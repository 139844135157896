import {
  Model_CommonCategoryFields,
  Model_UpdGlobalDiscount_CodeValues,
  ModelActionFields,
  ModelActionRuleCodeValues,
  DocumentTemplateFields,
  StructureItemFields,
  ModelTotalsItemFields,
} from 'types/vendor';
import { EnvironmentConstants } from '@hypercharge/xdms-client/lib/types.separate';

export const DEFAULT_CURRENCY = '€';
export const CURRENCIES = [
  {
    label: '€',
    value: 'EUR',
  },
  {
    label: '$',
    value: 'USD',
  },
];

export const GLOBAL_DISCOUNTS_LIST = [
  {
    code: Model_UpdGlobalDiscount_CodeValues.baseModel,
    valueField: ModelTotalsItemFields.baseModel_TotalDiscountPercent,
    tKey: 'TOTAL_GLOBAL_DISCOUNT_BASE_MODEL',
  },
  {
    code: Model_UpdGlobalDiscount_CodeValues.accessories,
    valueField: ModelTotalsItemFields.accessories_TotalDiscountPercent,
    tKey: 'TOTAL_GLOBAL_DISCOUNT_ACCESSORIES',
  },
  {
    code: Model_UpdGlobalDiscount_CodeValues.options,
    valueField: ModelTotalsItemFields.options_TotalDiscountPercent,
    tKey: 'TOTAL_GLOBAL_DISCOUNT_OPTIONS',
  },
];

export const API_XDMS_LOGIN_URL =
  process.env.REACT_APP_API_XDMS_LOGIN_URL ?? '/api/xdms/login-url';
export const API_CUSTOMER_DETAILS_URL = process.env.REACT_APP_API_CUSTOMER_DETAILS_URL!;
if (!API_CUSTOMER_DETAILS_URL)
  throw new Error('Env variable API_CUSTOMER_DETAILS_URL was not defined');

export enum STORAGE_KEYS {
  currency = '__hyper_currency__',
  vatEnabled = '__hyper_vat_price__',
  configurationNumber = '__hyper_configuration_number__',
  hostSettingsExternal = '__hyper_external_theme__',
  authToken = '__hyper_auth_token__',
  username = '__hyper_username__',
  baseUrl = '__hyper_base_url__',
  host = '__hyper_host__',
  customerId = 'dev_customerId',
  connectionId = 'connection_id',
  /** Uses 1 0 to represent boolean, so needs to be parsed to number first */
  isStreamingInitiallyEnabled = 'is_streaming_initially_enabled',
}
export const MAX_DESC_LENGTH = 256;
export const MAX_TEXT_LENGTH_IN_TABLE = 30;
export const MAX_TEXT_LENGTH_IN_MODAL_COLUMN = 40;

/** @todo: move to {@link URL_QUERY_PARAMS}; make restore category based on url search param */
export const ACCESSORIES_CATEGORY = 'ACCESSORIES_CATEGORY';
/** @todo: move to {@link URL_QUERY_PARAMS} */
export const PACKAGES_CATEGORY = 'PACKAGES_CATEGORY';
/** @todo: move to {@link URL_QUERY_PARAMS}; make restore category based on url search param */
export const OPTIONS_CATEGORY = 'OPTIONS_CATEGORY';
/** @todo: move to {@link URL_QUERY_PARAMS}; make restore category based on url search param */
export const TERMS_AND_CONDITIONS_CATEGORY = 'TERMS_AND_CONDITIONS_CATEGORY';
/** @todo: move to {@link URL_QUERY_PARAMS} */
export const ACC_SUBCATEGORY = 'ACC_SUBCATEGORY';
/** @todo: move to {@link URL_QUERY_PARAMS} */
export const PKG_SUBCATEGORY = 'PKG_SUBCATEGORY';
/** @todo: move to {@link URL_QUERY_PARAMS} */
export const OPT_SUBCATEGORY = 'OPT_SUBCATEGORY';
/** @todo: move to {@link URL_QUERY_PARAMS} */
export const TERMS_AND_CONDITIONS_SUBCATEGORY = 'TERMS_AND_CONDITIONS_SUBCATEGORY';

export enum URL_QUERY_PARAMS {
  configurationNumber = 'OFFER_ID', // 'NRconfig',
  modelPageExtendedView = 'EXTENDED_MODEL_TABLE_VIEW',
  // status of standard features switch
  standardFeaturesValue = 'SFV',
  presetDossier = 'DOSSIER',
  host = 'HOST',
  /** Disallows user to change host manually. */
  hostLock = 'HOST_LOCK',
  /* terminal customer ID to stream for by default */
  stream = 'STREAM',
  /* stock machine number to build configuration upon */
  stockMachine = 'STOCK_MACHINE',
}
export const URL_QUERY_PARAM__relationRegExp = /RELATION\d+/;

// + page urls
export const ACCESSORIES_PAGE_URL = 'ACC';
export const OPTIONS_PAGE_URL = 'OPT';
export const PACKAGES_PAGE_URL = 'PKG';
export const TERMS_AND_CONDITIONS_PAGE_URL = 'T&C';
export const MODELS_URL = '/models';
export const PRICE_LIST_URL = '/price-list';
export const XDMS_SETTINGS_URL = '/xdms-settings';
export const LOGIN_URL = '/login';
export const CLIENTS_URL = '/clients';
export const TRADE_IN_URL = '/trade-in';
export const TOTAL_URL = '/total';
export const SOFT_OFFER_URL = '/soft-offer';
export const FINANCING_URL = '/financing';
export const DOCUMENTS_URL = '/documents';
export const STEPS_URL = '/steps';
export const CONFIGURATIONS_URL = '/configurations';
export const INFO_URL = '/info';
export const TAXES_URL = '/taxes';
export const INDEX_URL = '/';
// - page urls
export const STRUCTURE_LABEL = 'STRUCT_';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_BE = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const VAT = 2;
/** @todo: move to {@link URL_QUERY_PARAMS} */
export const VIRTUAL_SUBCATEGORY = 'VIRTUAL_SUBCATEGORY';
export const USERNAME_PREFIX = '-WebConfig-';
export const FILTER_SIGNS = {
  EQUAL: 'equal' as const,
  INCLUDES: 'includes' as const,
  NOT_EQUAL: 'notEqual' as const,
};

export const SALE_STATUS_ID = '8';
export const SALE_DATE_STATUS_ID = '6';
export const CONFIGURATION_SOLD_STATUS_CODE = '6';

export const TABLE_ACTION_RULES = {
  noEdit: 'NO-EDIT',
  noStatusChange: 'NO-STATUSCHANGE',
  noNewVersion: 'NO-NEWVERSION',
};

export const TOTAL_DEALER_ACTION_CODE = 'DELTA';

export const TOTAL_DEALER_DELIV_ACTION_CODE = 'DELIV01';

export const TOTAL_ACTIONS_RULES_TRANSLATIONS_CODES = {
  [ModelActionRuleCodeValues.local]: 'TOTAL_ACTION_LOCAL',
  [ModelActionRuleCodeValues.custom]: 'TOTAL_ACTION_CUSTOM',
  [ModelActionRuleCodeValues.national]: 'TOTAL_ACTION_NATIONAL',
};

/** Which field has more weight to be rendered as name.
 * Should pick first which has value. */
export const TOTAL_PROMOTIONS__RULE_CODE__mapping__STATIC_NAME_FIELD_PRIORITY: Record<
  ModelActionRuleCodeValues,
  ModelActionFields[]
> = {
  [ModelActionRuleCodeValues.national]: [
    ModelActionFields.description,
    ModelActionFields.description2,
    ModelActionFields.name,
    ModelActionFields.actionCode,
  ],
  [ModelActionRuleCodeValues.custom]: [
    ModelActionFields.description,
    ModelActionFields.description2,
    ModelActionFields.name,
    ModelActionFields.actionCode,
  ],
  [ModelActionRuleCodeValues.local]: [
    ModelActionFields.name,
    ModelActionFields.actionCode,
    ModelActionFields.description,
    ModelActionFields.description2,
  ],
};

export const XDMS_CLIENT_ENVIRONMENT_CONSTANTS: Omit<
  EnvironmentConstants,
  'clientInstanceName'
> = {
  recordsCreatorSourceName: 'hyperportal',
  interfaceName: 'Hyper',
  appName: 'CONFIGURATOR',
};

export const MODELS_FILTERING_KEYS = [StructureItemFields.name, StructureItemFields.code];

export const EXTENDED_DYNAMIC_TABLE_VIEW = 'EXTENDED_DYNAMIC_TABLE_VIEW';

// default quantity to create model option with
// in case feature is disabled
export const DEFAULT_QUANTITY = 1;

export const DEFAULT_SFV = false;

export const IMPORT_TOKEN_SEPARATOR = '|';

export const IS_DEVELOPMENT_MODE = process.env.NODE_ENV === 'development';
export const IS_PRODUCTION_MODE = process.env.NODE_ENV === 'production';

export const DEFAULT_CUSTOMER_ID = 'default';
export const PROXY_DEFAULT_CUSTOMER_IDS_LIST = ['configurator'];

// nl
export const DEFAULT_SYSTEM_LANGUAGE_CODE = '';
export const DEFAULT_LANGUAGE = 'en';
export const DEFAULT_LANGUAGES_WHITELIST = ['en', 'nl', 'fr'];

// + images
const defaultImagesBaseUrl =
  'https://xdms-1.s3.eu-west-1.amazonaws.com/publicimages/default/';
// reference image asset for fallback login background
export const DEFAULT_AUTH_PAGES_BACKGROUND_IMAGE =
  defaultImagesBaseUrl + 'default-enter-bg.png';
export const DEFAULT_BACKGROUND_IMAGE = defaultImagesBaseUrl + 'login-bg.svg';

export const DEFAULT_FAVICON = defaultImagesBaseUrl + 'favicon.ico';

export const defaultImage = defaultImagesBaseUrl + 'no-image.jpg';
export const DEFAULT_LOGO = defaultImagesBaseUrl + 'default-logo.png';
// - images

export const REL_DATA_HOST_SETTINGS_DYNAMIC_PAGES_KEY_PREFIX =
  'hostSettingsDynamicPages:';
export const REL_DATA_HOST_SETTINGS_KEY_PREFIX = 'hostSettings:';
export const REL_DATA_THEME_KEY_PREFIX = 'hostSettingsPublic:';

export const DEFAULT_IMPORT_LINK_LANGUAGE_CODE = 'intl';

export const ERROR_REGEXP__SERVER_DOWN = /ERROR .+:.+\(7211\)/gm;

export const DEV_CUSTOMER_IDS_LIST = [
  DEFAULT_CUSTOMER_ID,
  'luyckx',
  'volvo',
  'merlo',
  'honda',
];

export const DOCUMENT_GENERATION_DOCTYPE__DOCUMENTS_PAGE = 'OFF';

// Draft
export const DOCUMENT_GENERATION_DOCTYPE_DRAFT = 'DRF';

// used to map templates based on
export const PRIMARY_DYNAMIC_FIELD = 'primary';

export const CATEGORIES_ORDER: (
  | Model_CommonCategoryFields.lvl1
  | Model_CommonCategoryFields.lvl2
  | Model_CommonCategoryFields.lvl3
  | Model_CommonCategoryFields.lvl4
)[] = [
  Model_CommonCategoryFields.lvl1,
  Model_CommonCategoryFields.lvl2,
  Model_CommonCategoryFields.lvl3,
  Model_CommonCategoryFields.lvl4,
];

export const IS_CATEGORY_REQUIRED_staticIdRegExp = /^\..+/;

export const IMAGE_GALLERY_THUMBNAIL_WIDTH = 100;
export const IMAGE_GALLERY_THUMBNAIL_HEIGHT = 56;

// can be saved to customer / contact email field
export const EMAIL_ALLOWED_VALUE = 'NOMAIL';

export const TOTAL_DEFAULT_FILENAME = 'total_document.pdf';

export const TOTAL_DEALER_ACTION_ADDITIONAL_COST_LIMIT = 1;

export const LANGUAGE_CODE_mapping_PRINT_TEMPLATE_DESCRIPTION_FIELD = {
  '': DocumentTemplateFields.description,
  '1': DocumentTemplateFields.description1,
  '2': DocumentTemplateFields.description2,
  '3': DocumentTemplateFields.description3,
};

export const TEST_MAX_MESSAGE_LENGTH_TO_DISPLAY = 100;

export const TEMP__SOFT_OFFER_PAYMENT_OPTIONS = [
  { label: 'Half yearly', value: 'half_yearly' },
  { label: 'Quarterly', value: 'Quarterly' },
  { label: 'Annual', value: 'Annual' },
];

// streaming server has job running once 5 minutes, it syncs state for all terminals
export const STREAMING_TERMINAL_SYNC_PERIOD_IN_SECONDS = 300;

export const SOFT_OFFER_VEHICLE_TRAILER1TYPE_BLANK_VALUE = 'No_Trailer';
export const SOFT_OFFER_INSURANCE_REFUSED_PREVIOUSLY_REASON_CODE_OTHER = '3';
export const SOFT_OFFER_INSURANCE_POLICY_HOLDER_PERSON_TYPE_COMPANY = 'Enterprise';

export const SOFT_OFFER_GUARANTEE_FORMULA_TO_DEDUCTIBLE_FORMULA_MAPPING = {
  Formula_0: 'Percent_2',
  Formula_12: 'Percent_2',
  Formula_24: 'English_deductible_750',
  Formula_36: 'Percent_0',
};

export const PAGES_WITH_REQUIRED_CATEGORIES: (
  | typeof OPTIONS_PAGE_URL
  | typeof ACCESSORIES_PAGE_URL
  | typeof TERMS_AND_CONDITIONS_PAGE_URL
  | typeof PACKAGES_PAGE_URL
)[] = [OPTIONS_PAGE_URL];

export const LOOPEND_INTEGRATION_SCRIPT =
  'https://cdn.loopedin.io/js/sidebar.min.js?v=0.1';
