import React, {
  useEffect,
  useMemo,
  ReactElement,
  useCallback,
  useState,
  FC,
  ChangeEvent,
} from 'react';
import { get } from 'utils';
import { useSoftOfferApi } from 'context/softOffer/SoftOfferProvider';
import { useFeature } from 'context/feature/FeatureProvider';
import { Button } from 'components/button';
import { useColumns } from './use-columns';
import { RightComponent } from '../TradeIn/TradeInTable.styles';
import { SoftOfferListItemFields } from '@hypercharge/xdms-client/lib/softOffer/types';
import { useStep } from 'context/step/StepProvider';
import { GlobalFeaturesFlagsFields } from 'common/globalFeaturesFlags';
import Layout from 'layout/Default/Layout';
import { SOFT_OFFER_URL } from 'common/constants';
import { ScTable } from './SoftOfferTable.styles';
import { useSelector } from 'react-redux';
import { configurationSelectors, modelSelectors, softOffersSelectors } from 'store';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { SoftOfferListItem } from '@hypercharge/xdms-client/lib/types';
import { checkWhetherRecordContainsString } from 'utils/check-whether-record-contains-string';
import TableFilters from 'components/table/filters';
import { DEFAULT_TABLE_PAGE_SIZE } from 'utils/constants';
import { useStreaming } from 'context/streaming/StreamingProvider';
import { StreamingEventType } from 'context/streaming/types';

export const SoftOfferTable: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { sendMessage } = useStreaming();
  const { getSoftOffersList, deleteSoftOffer, createSoftOffer } = useSoftOfferApi();
  const { handlePrevStep, handleNextStep } = useStep();

  const modelToken = useSelector(modelSelectors.getToken);
  const { configurationNumber } = useSelector(
    configurationSelectors.getConfigurationCommonVariables,
  );
  const softOffersList = useSelector(softOffersSelectors.getList);

  const { isFeatureEnabled } = useFeature();

  useEffect(() => {
    if (configurationNumber) getSoftOffersList(configurationNumber);
  }, [getSoftOffersList, configurationNumber]);

  const isAllowAddNewSoftOffer = isFeatureEnabled({
    feature: GlobalFeaturesFlagsFields.allowSoftOfferAddNew,
  });

  const [searchValue, setSearchValue] = useState<string>('');
  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value);
    },
    [setSearchValue],
  );

  const tableData = useMemo<SoftOfferListItem[]>(() => {
    return (
      softOffersList?.filter(softOffer =>
        checkWhetherRecordContainsString(softOffer, searchValue),
      ) ?? []
    );
  }, [searchValue, softOffersList]);

  const columns = useColumns({
    softOfferEditAction: softOfferId => {
      history.push(`${SOFT_OFFER_URL}/${softOfferId}`);
    },
    softOfferDeleteAction: async softOfferRowId => {
      const item = softOffersList?.find(
        item => item[SoftOfferListItemFields.id] === softOfferRowId,
      );
      if (!item) return;
      await deleteSoftOffer(item);
      if (!configurationNumber) return;
      await getSoftOffersList(configurationNumber);
    },
  });

  useEffect(() => {
    if (modelToken) {
      sendMessage({
        type: StreamingEventType.EMIT_SLOT_CHANGE,
        data: {
          name: 'selection',
          data: { token: modelToken },
        },
      });
    }
  }, [modelToken, sendMessage]);

  const AddNewButton = useMemo<ReactElement | null>(() => {
    if (!isAllowAddNewSoftOffer) return null;
    return (
      <RightComponent data-testid="soft-offer-step-table-filter-buttons-container">
        <Button
          onClick={async () => {
            if (!configurationNumber) return;
            const { response } = await createSoftOffer(configurationNumber);
            if (!response) return;
            history.push({ pathname: './' + response });
          }}
          variant="highlighted"
          data-testid="soft-offer-add"
        >
          {t('SOFT_OFFER_ADD_NEW')}
        </Button>
      </RightComponent>
    );
  }, [configurationNumber, createSoftOffer, history, isAllowAddNewSoftOffer, t]);

  return (
    <Layout bg={false}>
      <TableFilters
        scrollMenuOptions={[]}
        handlePrevStep={handlePrevStep}
        handleNextStep={handleNextStep}
        onSelect={() => {}}
        prevStepDisabled={false}
        nextStepDisabled={false}
        searchValue={searchValue}
        onSearch={handleSearch}
        extraRight={AddNewButton}
      />
      <ScTable
        rowKey={record => get(record, SoftOfferListItemFields.id)}
        data={tableData}
        columns={columns}
        scroll={{ x: 1200 }}
        pagination={tableData.length > DEFAULT_TABLE_PAGE_SIZE}
      />
    </Layout>
  );
};
